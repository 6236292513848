@tailwind base;
@tailwind components;
@tailwind utilities;

/* TODO: instruct Nginx to cache these indefinitely */
@font-face {
  font-family: 'Euclid Square';
  src: url('../public/fonts/EuclidSquare-Regular.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Square';
  src: url('../public/fonts/EuclidSquare-Medium.woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Square';
  src: url('../public/fonts/EuclidSquare-Bold.woff2');
  font-weight: 700;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.47);
}

body {
  font-family: 'Euclid Square';
  background-color: #f3f4f6;
}

/* TODO: find a better solution for this */
/* React MUI style overrides */
.MuiDataGrid-root {
  background-color: white;
}

.MuiDataGrid-columnHeaders {
  /* background-color: #7e82ac; */
  background-color: #6c7193;
  color: white;
}

.sticky-actions-column {
  position: sticky;
  right: 0;
  z-index: 1;
  background-color: #ffffff;
}

 #text {
  opacity: 1;
  transition: 2s ease-in-out;
  border: 1px solid black;
  border-radius: 5px;
  height: 55px;
  padding: 15px;
}

#text.fade {
  opacity: 50;
}